import React, { Component } from 'react';

import './Boxer.css';

class Boxer extends Component {

  render() {
    return (
      <div className={this.props.className + ' boxer'}>
        {/* <center>
          <img src={this.getFlag(this.props.boxer.club)} alt="flag" className="flag" />
        </center> */}
        <div className="name">
          {this.props.boxer.name}
        </div>
      </div>
    )
  }
}

export default Boxer;
