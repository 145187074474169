import React, { Component } from 'react';
import Logo from './logo-white.svg';
import './App.css';
import { getBouts } from './boutService';
import Bout from './Bout/Bout';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      A: {
        boutno: "",
        b1: {
          name: "",
          club: ""
        },
        b2: {
          name: "",
          club: ""
        }
      },
      B: {
        boutno: "",
        b1: {
          name: "",
          club: ""
        },
        b2: {
        name: "",
        club: ""
        }
      },
      C: {
        boutno: "",
        b1: {
          name: "",
          club: ""
        },
        b2: {
        name: "",
        club: ""
        }
      }
    }
  }

  componentDidMount() {
    document.body.style.cursor = 'none';
    this.updateBouts();
    window.setInterval(() => this.updateBouts(), 5000);
  }

  updateBouts = () => {
    getBouts().then(data => {
      this.setState({ A: data.A, B: data.B, C: data.C });
    }).catch(err => console.log(err));
    ;
  }

  render() {
    return (
      <div className="App">
        <center>
          <img src={Logo} style={{ width: '40%', padding: '50px', marginTop: '40px', marginBottom: '40px'  }} alt="Logo"/>
        </center>
        <div>
          {this.state.A != undefined
            ? <Bout bout={this.state.A} ring="A"/>
            : null
          }
          {this.state.B != undefined
            ? <Bout bout={this.state.B} ring="B"/>
            : null
          }
          {this.state.C != undefined
            ? <Bout bout={this.state.C} ring="C"/>
            : null
          }
        </div>
      </div>
    );
  }
}

export default App;
