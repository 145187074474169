import React, { Component } from 'react';
import Boxer from '../Boxer/Boxer';
import './Bout.css'

class Bout extends Component {
  render() {
    return (
      <div className="bout">
        <div className="ring">
          <b>
            RING {this.props.ring}
          </b>
        </div>
        <div className="number">
          <b>
            {this.props.bout.ring_bout_no}
          </b>
        </div>
        <Boxer className="red" boxer={this.props.bout.b1}/>
        <div className="vs">
          vs
        </div>
        <Boxer className="blue" boxer={this.props.bout.b2}/>
      </div>
    )
  }
}

export default Bout;
